<template>
  <section>
    <div class="card p-5" style="width: 400px;">
      <h5 class="text-center">Change Password</h5>
      <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
        <div class="field">
          <div class="p-float-label">
            <Password
              id="password"
              v-model="password"
              :class="{ 'p-invalid': v$.password.$invalid && submitted }"
              :feedback="false"
              toggleMask
            >
            </Password>
            <label
              for="password"
              :class="{ 'p-error': v$.password.$invalid && submitted }"
              >Password*</label
            >
          </div>
          <small v-if="v$.password.$error" class="p-error">{{
            v$.password.$errors[0].$message
          }}</small>
        </div>

        <Button type="submit" label="Submit" class="p-mt-2" />
      </form>
    </div>
  </section>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, minLength } from '@vuelidate/validators';

import Password from 'primevue/password';

export default {
  name: 'ChangePassword',
  setup: () => ({ v$: useVuelidate() }),
  components: {
    Password,
  },

  data() {
    return {
      password: '',
      submitted: false,
      showMessage: false,
    };
  },
  validations() {
    return {
      password: {
        required: helpers.withMessage(
          () => 'Enter a new password.',
          required,
        ),
        minLength: helpers.withMessage(
          () => 'Password must have a minimum of 8 characters.',
          minLength(8),
        ),
      },
    };
  },
  created() {
    console.log('User', this.$store.state.user);
  }, // created()
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true;
      this.v$.$touch();

      if (!isFormValid) {
        return;
      }
      this.axios
        .post('/changePassword', {
          password: this.password,
          email: this.$store.state.user.email,
        })
        .then(() => {})
        .then(() => {
          this.$swal({
            icon: 'success',
            text: 'Your password has been reset!',
          }).then(() => this.$router.push({ name: 'Dashboard' }));
        });
    },
  }, // methods()
};
</script>

<style></style>
