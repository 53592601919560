<template>
  <Card style="width: 600px; margin: auto">
    <template #header>
      <TabMenu :model="items" />
    </template>
    <template #content>
      <router-view />
    </template>
  </Card>
</template>

<script>
import TabMenu from 'primevue/tabmenu';
import Card from 'primevue/card';

export default {
  components: { TabMenu, Card },
  data() {
    return {
      items: [
        { label: 'Sign in', icon: 'fas fa-sign-in', to: '/user/signin' },
        { label: 'Register', icon: 'fas fa-user-cog', to: '/user/register' },
        {
          label: 'Forgot Password',
          icon: 'fas fa-key',
          to: '/user/forgotpassword',
        },
      ],
    };
  },
};
</script>

<style></style>
