<template>
  <div class="form-demo flex">
    <div class="flex-1">
      <div class="card p-3">
        <h5 class="text-center el-h5">Sign In</h5>
        <form @submit.prevent="handleSubmit" class="p-fluid">
          <!--email-->
          <div class="field">
            <div class="p-float-label p-input-icon-right">
              <i class="fas fa-envelope" />
              <InputText
                id="email"
                v-model="email"
                :class="{ 'p-invalid': v$.email.$error }"
                aria-describedby="email-error"
              />
              <label for="email">Email*</label>
            </div>
            <span
              v-if="v$.email.$error"
              class="p-error help-text"
              id="email-error"
            >
              <small class="p-error">{{ v$.email.$errors[0].$message }}</small>
            </span>
          </div>

          <!--Password-->
          <div class="field mt-5">
            <div class="p-float-label">
              <Password
                id="password"
                v-model="password"
                :showIcon="'fas fa-eye'"
                :hideIcon="'fas fa-eye-slash'"
                :class="{ 'p-invalid': v$.password.$error }"
                :feedback="false"
                toggleMask
              />

              <label for="password" :class="{ 'p-error': v$.password.$error }"
                >Password*</label
              >
            </div>
            <span v-if="v$.password.$error" class="help-text p-error">
              <small>{{v$.password.$errors[0].$message}}</small>
            </span>
          </div>

          <!--remember-->
          <div class="field-checkbox">
            <Checkbox
              id="remember"
              name="remember"
              :binary="true"
              v-model="remember"
            />
            <label for="remember">Keep me signed in.</label>
          </div>

          <Button type="submit" label="Sign In" class="p-mt-2" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  helpers,
  email,
  required,
  minLength,
  maxLength,
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

import Password from 'primevue/password';
import Checkbox from 'primevue/checkbox';

export default {
  name: 'Signin',
  setup: () => ({ v$: useVuelidate() }),
  components: {

    Password,
    Checkbox,
  },
  data() {
    return {
      email: '',
      password: '',
      remember: true,
      submitted: false,
      showMessage: false,
    };
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage(
          'A valid email address is required.',
          required,
        ),
        email: helpers.withMessage('Enter a valid email address.', email),
      },
      password: {
        required: helpers.withMessage('Password is required.', required),
        minLength: helpers.withMessage(
          'Password must have a minimum of 8 characters.',
          minLength(8),
        ),
        maxLength: helpers.withMessage(
          'Password cannot be more than 32 characters.',
          maxLength(32),
        ),
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      const data = { email: this.email, password: this.password };
      this.axios.post('/signin', data).then((response) => {
        console.log(response);
        if (response.data.JWT) {
          if (this.remember) {
            localStorage.setItem('JWT', response.data.JWT);
          }
          this.axios.defaults.headers.Authorization = `Bearer ${localStorage.JWT}`;
          this.$store.dispatch('signIn', response.data);
          this.$router.push({ name: 'Dashboard' });
        } else {
          this.$swal({
            psition: 'top',
            icon: 'error',
            title: 'Oops',
            text:
              'The email or password you entered was incorrect. You can try again, or reset your password.',
          });
        }
      });
    },
  },
};
</script>

<style>
.form-demo .card {
  min-width: 450px;
}
.form-demo form {
  margin-top: 2rem;
}

.form-demo .field {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
  .card {
    width: 80%;
  }
}
</style>
