<template>
  <div class="form-demo flex">
    <div class="flex-1">
      <div class="card p-3">
        <h5 class="text-center">Register</h5>
        <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
          <div class="field">
            <div class="p-float-label">
              <InputText
                id="name"
                v-model="v$.name.$model"
                :class="{ 'p-invalid': v$.name.$invalid && submitted }"
              />
              <label for="name" :class="{ 'p-error': v$.name.$invalid && submitted }">Name*</label>
            </div>
            <small
              v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response"
              class="p-error"
              >{{ v$.name.required.$message.replace("Value", "Name") }}</small
            >
          </div>
          <p></p>
          <div class="field">
            <div class="p-float-label p-input-icon-right">
              <i class="fas fa-envelope" />
              <InputText
                id="email"
                v-model="v$.email.$model"
                :class="{ 'p-invalid': v$.email.$invalid && submitted }"
                aria-describedby="email-error"
              />
              <label for="email" :class="{ 'p-error': v$.email.$invalid && submitted }"
                >Email*</label
              >
            </div>
            <span v-if="v$.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small
              v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response"
              class="p-error"
              >{{ v$.email.required.$message.replace("Value", "Email") }}</small
            >
          </div>
          <div class="field">
            <div class="p-float-label">
              <Password
                id="password"
                v-model="v$.password.$model"
                :showIcon="'fas fa-eye'"
                :hideIcon="'fas fa-eye-slash'"
                :class="{ 'p-invalid': v$.password.$invalid && submitted }"
                toggleMask
              >
                <template #header>
                  <h6>Select a password</h6>
                </template>
                <template #footer="sp">
                  {{ sp.level }}
                  <Divider />
                  <p class="mb-2 el-button">Suggestions:</p>
                  <ul class="el-caption" style="line-height: 1.5">
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one number</li>
                    <li>Minimum 8 characters</li>
                  </ul>
                </template>
              </Password>
              <label for="password" :class="{ 'p-error': v$.password.$invalid && submitted }"
                >Password*</label
              >
            </div>
            <small
              v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response"
              class="p-error"
              >{{ v$.password.required.$message.replace("Value", "Password") }}</small
            >
          </div>

          <div class="field-checkbox">
            <Checkbox
              id="accept"
              name="accept"
              value="Accept"
              v-model="v$.accept.$model"
              :class="{ 'p-invalid': v$.accept.$invalid && submitted }"
            />
            <label for="accept" :class="{ 'p-error': v$.accept.$invalid && submitted }"
              >I agree to the terms and conditions*</label
            >
          </div>
          <Button type="submit" label="Submit" class="p-mt-2" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import Password from "primevue/password";
import Divider from "primevue/divider";

import Checkbox from "primevue/checkbox";

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    Password,
    Divider,

    Checkbox
  },

  data() {
    return {
      name: "",
      email: "",
      password: "",
      accept: null,
      submitted: false,
      showMessage: false
    };
  },
  countryService: null,
  validations() {
    return {
      name: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required
      },
      accept: {
        required
      }
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.toggleDialog();
    },
    toggleDialog() {
      this.showMessage = !this.showMessage;

      if (!this.showMessage) {
        this.resetForm();
      }
    },
    resetForm() {
      this.name = "";
      this.email = "";
      this.password = "";
      this.accept = null;
      this.submitted = false;
    }
  }
};
</script>

<style>
.form-demo .card {
  min-width: 450px;
}
.form-demo form {
  margin-top: 2rem;
}

.form-demo .field {
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 960px) {
  .card {
    width: 80%;
  }
}
</style>
